
import { contractStakingV2 } from 'utils/getContractAddress';
import WanakaFarmAbi from 'Contracts/farm2/WanakaFarmAbi.json';
import WanakaFarmLPAbi from 'Contracts/farm2/WanakaFarmLPAbi.json';

export const UNLIMITED_WANA = 200000000
const POOL_LIMIT = {
    30: 2115000,
    90: 3145000,
    180: UNLIMITED_WANA
}
const PACKAGE_DATA = {
    WANA: [
        {
            id: 1,
            name: 'Rank-I',
            tokenName: 'WANA',
            image: '/package/silver.webp',
            description: '',
            minStake: 250,
            maxStake: 3999,
            abi: WanakaFarmAbi,
            contractFarm: contractStakingV2[56].WanakaFarmStaking,
            contractToken: contractStakingV2[56].WanakaFarmToken,
            options: [
                {
                    id: 1,
                    name: '7 days',
                    duration: 7,
                    rate: 8,
                    poolLimit: 1000000,
                    isDefault: false,
                    display: false
                },
                {
                    id: 2,
                    name: '30 days',
                    duration: 30,
                    rate: 20,
                    gift: '',
                    poolLimit: 210000,
                    poolLimitTotal: POOL_LIMIT[30],
                    isDefault: true,
                    display: true
                },
                {
                    id: 3,
                    name: '90 days',
                    duration: 90,
                    rate: 35,
                    gift: '',
                    poolLimit: 40000, // unlimited is 200000000 because WANA total is 200000000
                    poolLimitTotal: POOL_LIMIT[90],
                    isDefault: false,
                    display: true
                },
                {
                    id: 4,
                    name: '180 days',
                    duration: 180,
                    rate: 55,
                    gift: 'Random 5 Rolly Pet Egg monthly',
                    giftDetail: '*',
                    poolLimit: UNLIMITED_WANA,
                    poolLimitTotal: POOL_LIMIT[180],
                    isDefault: false,
                    display: true
                },
            ],
        },
        {
            id: 2,
            name: 'Rank-II',
            tokenName: 'WANA',
            image: '/package/Capitalist.webp',
            description: '',
            minStake: 4000,
            maxStake: 14999,
            abi: WanakaFarmAbi,
            contractFarm: contractStakingV2[56].WanakaFarmStaking,
            contractToken: contractStakingV2[56].WanakaFarmToken,
            options: [
                {
                    id: 1,
                    name: '7 days',
                    duration: 7,
                    rate: 10,
                    poolLimit: 1000000,
                    isDefault: false,
                    display: false
                },
                {
                    id: 2,
                    name: '30 days',
                    duration: 30,
                    rate: 25,
                    gift: '',
                    poolLimit: 310000,
                    poolLimitTotal: POOL_LIMIT[30],
                    isDefault: false,
                    display: true
                },
                {
                    id: 3,
                    name: '90 days',
                    duration: 90,
                    rate: 40,
                    gift: 'Random 5 Talpa Pet Egg monthly',
                    giftDetail: '*',
                    poolLimit: 279000, // unlimited is 200000000 because WANA total is 200000000
                    poolLimitTotal: POOL_LIMIT[90],
                    isDefault: true,
                    display: true
                },
                {
                    id: 4,
                    name: '180 days',
                    duration: 180,
                    rate: 65,
                    gift: 'Top 10 get a Landbox',
                    giftDetail: '**',
                    poolLimit: UNLIMITED_WANA,
                    poolLimitTotal: POOL_LIMIT[180],
                    isDefault: false,
                    display: true
                },
            ],
        },
        {
            id: 3,
            name: 'Rank-III',
            tokenName: 'WANA',
            image: '/package/ruby.webp',
            description: '',
            minStake: 15000,
            maxStake: 29999,
            abi: WanakaFarmAbi,
            contractFarm: contractStakingV2[56].WanakaFarmStaking,
            contractToken: contractStakingV2[56].WanakaFarmToken,
            options: [
                {
                    id: 1,
                    name: '7 days',
                    duration: 7,
                    rate: 12,
                    poolLimit: 1000000,
                    isDefault: false,
                    display: false
                },
                {
                    id: 2,
                    name: '30 days',
                    duration: 30,
                    rate: 35,
                    gift: '',
                    poolLimit: 650000,
                    poolLimitTotal: POOL_LIMIT[30],
                    isDefault: false,
                    display: true
                },
                {
                    id: 3,
                    name: '90 days',
                    duration: 90,
                    rate: 50,
                    gift: 'Random 3 Landbox monthly',
                    giftDetail: '*',
                    poolLimit: 481000, // unlimited is 200000000 because WANA total is 200000000
                    poolLimitTotal: POOL_LIMIT[90],
                    isDefault: true,
                    display: true
                },
                {
                    id: 4,
                    name: '180 days',
                    duration: 180,
                    rate: 70,
                    gift: 'Top 15 get a Landbox',
                    giftDetail: '**',
                    poolLimit: UNLIMITED_WANA,
                    poolLimitTotal: POOL_LIMIT[180],
                    isDefault: false,
                    display: true
                },
            ],
        },
        {
            id: 4,
            name: 'Rank-IV',
            tokenName: 'WANA',
            image: '/package/diamond.webp',
            description: '',
            minStake: 30000,
            maxStake: UNLIMITED_WANA,
            abi: WanakaFarmAbi,
            contractFarm: contractStakingV2[56].WanakaFarmStaking,
            contractToken: contractStakingV2[56].WanakaFarmToken,
            options: [
                {
                    id: 1,
                    name: '7 days',
                    duration: 7,
                    rate: 15,
                    poolLimit: 1000000,
                    isDefault: false,
                    display: false
                },
                {
                    id: 2,
                    name: '30 days',
                    duration: 30,
                    rate: 45,
                    gift: 'Get a Rolly Pet Egg',
                    giftDetail: '***',
                    poolLimit: 945000,
                    poolLimitTotal: POOL_LIMIT[30],
                    isDefault: false,
                    display: true
                },
                {
                    id: 3,
                    name: '90 days',
                    duration: 90,
                    rate: 60,
                    gift: 'Get an Incubator',
                    giftDetail: '***',
                    poolLimit: 2345000, // unlimited is 200000000 because WANA total is 200000000
                    poolLimitTotal: POOL_LIMIT[90],
                    isDefault: false,
                    display: true
                },
                {
                    id: 4,
                    name: '180 days',
                    duration: 180,
                    rate: 80,
                    gift: 'Top 20 get a Landbox and an Incubator',
                    giftDetail: '**',
                    poolLimit: UNLIMITED_WANA,
                    poolLimitTotal: POOL_LIMIT[180],
                    isDefault: true,
                    display: true
                },
            ],
        },
    ],
    WANA_LP: [
        {
            id: 5,
            name: 'Pool-LP',
            tokenName: 'WANA-LP',
            image: '/package/silver.webp',
            description: '',
            minStake: 50,
            maxStake: UNLIMITED_WANA,
            abi: WanakaFarmLPAbi,
            contractFarm: contractStakingV2[56].WanakaFarmLp,
            contractToken: contractStakingV2[56].WanakaFarmTokenLP,
            contractTokenPair1: "0x78867bbeef44f2326bf8ddd1941a4439382ef2a7", // busd
            contractTokenPair2: contractStakingV2[56].WanakaFarmToken, // wana
            options: [
                {
                    id: 1,
                    name: '7 days',
                    duration: 7,
                    rate: 8,
                    poolLimit: 1000000,
                    isDefault: false,
                    display: false
                },
                {
                    id: 2,
                    name: '30 days',
                    duration: 30,
                    rate: 20,
                    poolLimit: 2500000,
                    isDefault: false,
                    display: false
                },
                {
                    id: 3,
                    name: '90 days',
                    duration: 90,
                    rate: 35,
                    poolLimit: UNLIMITED_WANA, // unlimited is 200000000 because WANA total is 200000000
                    isDefault: true,
                    display: true
                },
                {
                    id: 4,
                    name: '180 days',
                    duration: 180,
                    rate: 55,
                    poolLimit: UNLIMITED_WANA,
                    isDefault: false,
                    display: false
                },
            ],
        }
    ]
}

export const LIST_POOL = [
    PACKAGE_DATA.WANA[0],
    // PACKAGE_DATA.WANA_LP[0]
]

export default PACKAGE_DATA
