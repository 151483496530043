import bnb from 'Assets/icons/binance-smart-chain-icon.png';

import IconWanaka from 'Assets/icons/wanaka.svg';


const contractAddress = {
  //BSC Mainnet
  56: {
    USDT: { address: '0xe9e7cea3dedca5984780bafc599bd69add087d56', symbol: 'BUSD', decimals: '18' },
    PairUsdtNative: '0x65b51bc890C24C0Da163289B68480020222B4332', // pair WANA-BUSD,
    PairUsdtNative2: '0xc2b068161d308ab9794098992ad036b23e0b57fb', // pair WAi-BUSD
  },
  //BSC Testnet
  97: {
    USDT: { address: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7', symbol: 'BUSD', decimals: '18' },
    PairUsdtNative: '0x702F01801F61E91EEf4A62ca52D796dAA2323fd0', // pair WANA-BUSD
    PairUsdtNative2: '0x4c0b578299ff7767336f2c6deab1ce43501d6897', // pair WAi-BUSD
  },
};

const listTokensFarmDefault = {
  56: [
    {
      icon: IconWanaka,
      namePair: 'WANA-BUSD',
      addressLP: '0x65b51bc890C24C0Da163289B68480020222B4332', // BUSD-WANA
      wana: '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E',
      contractFarm: '0xe60cd955152fB19A948d60382c717eaBf1063D85',
      contractVesting: '0xDb81f24453991d0942895f144Cb5C2fD900e3fa6',
      token0: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // it can be an address or a symbol
      symbolEarn: 'WANA',
      decimalsWana: 18,
      allowanceFarm: 0,
      pendingReward: 0,
      amountStake: 0,
      balanceLP: 0,
      claimableAmount: 0,
      amountLocking: 0,
      vestingDuration: 403200,
      apr: 37.11,
      yearlyWanaReward: 458957,
      multiplier: 0.22,
      autoRestake: false,
      lockConfig: {
        groupInHours: "86400",
        lockInHours: "7776000"
      },
      isActive: true,
      textClose: '',
      linkDetail: ''
    },
    {
      icon: IconWanaka,
      namePair: 'WAI-BUSD',
      addressLP: '0xc2b068161d308ab9794098992ad036b23e0b57fb', // BUSD-WAI
      wai: '0x934c9198582bf2631128c5d4b051aacef9a6224f',
      contractFarm: '0x39A3e81B78669f318De85b625B1EE1D697329deC',
      contractVesting: '0x0000000000000000000000000000000000000000',
      contractFeeDeposit : '0x73fD9C122F3FA5A9b739516a8bbdeb1F21d6e507',
      token0: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // it can be an address or a symbol
      symbolEarn: 'WAI',
      decimalsWana: 18,
      allowanceFarm: 0,
      pendingReward: 0,
      amountStake: 0,
      balanceLP: 0,
      claimableAmount: 0,
      amountLocking: 0,
      vestingDuration: 0,
      apr:0.75,
      yearlyWanaReward: 0,
      multiplier: 0.01,
      autoRestake: false,
      lockConfig: {
        groupInHours: "86400",
        lockInHours: 0
      },
      contractType: "FarmLottery",
      isActive: false,
      textClose: 'Closed from July 31st 2022 - 23:59:59 UTC',
      linkDetail: 'https://bit.ly/3BaiyXI'
    },
  ],
  97: [
    // {
    //   icon: IconWanaka,
    //   namePair: 'WANA-BUSD',
    //   addressLP: '0x702f01801f61e91eef4a62ca52d796daa2323fd0', // BUSD-WANA
    //   wana: '0x56190b59760d472450f65dca7c54e50cf5ebbd41',
    //   contractFarm: '0xDC32B155a77681fB1A6B1DDE52713087bFcb0Ff4',
    //   contractVesting: '0x5f749440A4E9bc4a1746C3767177346C25Bb5c5a',
    //   token0: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7', // it can be an address or a symbol
    //   symbolEarn: 'WANA',
    //   decimalsWana: 18,
    //   allowanceFarm: 0,
    //   pendingReward: 0,
    //   amountStake: 0,
    //   balanceLP: 0,
    //   claimableAmount: 0,
    //   amountLocking: 0,
    //   vestingDuration: 400,
    //   apr: 0,
    //   yearlyWanaReward: 458957,
    //   multiplier: 0.02,
    //   autoRestake: false,
    //   lockConfig: {
    //     groupInHours: "3600",
    //     lockInHours: "7200"
    //   }
    // },
    // {
    //   icon: IconWanaka,
    //   namePair: 'WANA-BUSD',
    //   addressLP: '0x702f01801f61e91eef4a62ca52d796daa2323fd0', // BUSD-WANA
    //   wana: '0x56190b59760d472450f65dca7c54e50cf5ebbd41',
    //   contractFarm: '0xF54fC8f45c3e0b0752Bb4B2d5fECD3a1A6825747',
    //   contractVesting: '0xf4972fec2BeE97A85875d99cAf21F06BE0b202C9',
    //   token0: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7', // it can be an address or a symbol
    //   symbolEarn: 'WANA',
    //   decimalsWana: 18,
    //   allowanceFarm: 0,
    //   pendingReward: 0,
    //   amountStake: 0,
    //   balanceLP: 0,
    //   claimableAmount: 0,
    //   amountLocking: 0,
    //   vestingDuration: 400,
    //   apr: 0,
    //   yearlyWanaReward: 458957,
    //   multiplier: 0.22,
    //   autoRestake: false,
    //   lockConfig: {
    //     groupInHours: "604800",
    //     lockInHours: "7776000"
    //   }
    // },
    // {
    //   icon: IconWanaka,
    //   namePair: 'WAI-BUSD',
    //   addressLP: '0x4c0b578299ff7767336f2c6deab1ce43501d6897', // BUSD-WAI
    //   wai: '0xcd2d09fa9d425682085bc94ed0c7bada6cabcdba',
    //   contractFarm: '0x038a4e10B87F7DC3396C89bd0e2D990Cfed9C9F1',
    //   contractVesting: '0x0000000000000000000000000000000000000000',
    //   contractFeeDeposit : '0xEAf35E1D8C5135fe8F08aDF2025F694105CA7660',
    //   token0: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7', // it can be an address or a symbol
    //   symbolEarn: 'WAI',
    //   decimalsWana: 18,
    //   allowanceFarm: 0,
    //   pendingReward: 0,
    //   amountStake: 0,
    //   balanceLP: 0,
    //   claimableAmount: 0,
    //   amountLocking: 0,
    //   vestingDuration: 0,
    //   apr: 0,
    //   yearlyWanaReward: 0,
    //   multiplier: 0.01,
    //   autoRestake: false,
    //   lockConfig: {
    //     groupInHours: "86400",
    //     lockInHours: 0
    //   },
    //   contractType: "FarmLottery"
    // }
  ],
};

const listTokensPoolDefault = {
  56: [
    {
      icon: IconWanaka,
      namePair: 'WANA',
      addressLP: '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E', // wana address
      pairTokenAndNative: '0x65b51bc890C24C0Da163289B68480020222B4332', // wana-busd
      contractPool: '0x808b796b3D6A7885f4Ac3498F68ccC8130f2C304',
      token0: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // it can be an address or a symbol
      symbolEarn: 'WANA',
      decimalsLP: 18,
      allowanceFarm: 0,
      pendingReward: 0,
      amountStake: 0,
      balanceLP: 0,
      vestingDuration: 0,
      apr: 5.56,
      yearlyWanaReward: 183583,
      multiplier: 0.015,// reward per block
      autoRestake: false,
      statusCompound: false,
      rewardLockerDuration: 201600, // block,
      isActive: false,
      textClose: 'Closed from July 20th, 2022.',
      linkDetail: 'https://bit.ly/3uTZzMU'
    },
    {
      icon: IconWanaka,
      namePair: 'WANA',
      addressLP: '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E', // wana address
      pairTokenAndNative: '0x65b51bc890C24C0Da163289B68480020222B4332', // wana-busd
      contractPool: '0xe9A11a64b62237a0A166e562ba3FB5Bb1B4a1D7C',
      token0: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // it can be an address or a symbol
      contractRewardLocker: '0x9c8ebda9e34118ec5b60c681a1c191eae30ee13e',
      contractStakeLocker: '0x94f95fa060504e01b8a7e3441a6887d7e290e22e',
      symbolEarn: 'WANA',
      decimalsLP: 18,
      allowanceFarm: 0,
      pendingReward: 0,
      amountStake: 0,
      balanceLP: 0,
      vestingDuration: 403200,
      apr: 12.31,
      multiplier: 0.09,// reward per block
      autoRestake: false,
      statusCompound: false,
      rewardLockerDuration: 0,
      stakeLockerByBlock: 2592000,
      disabled: true,
      isActive: false,
      textClose: 'Closed from July 31st 2022 - 23:59:59 UTC',
      linkDetail: 'https://bit.ly/3BaiyXI'
    },
    {
      icon: IconWanaka,
      namePair: 'WANA',
      addressLP: '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E', // wana address
      pairTokenAndNative: '0x65b51bc890C24C0Da163289B68480020222B4332', // wana-busd
      contractPool: '0x5b458232A0827ADF1aA93Daac6d3CAb2eC0c3E7e',
      token0: '0xe9e7cea3dedca5984780bafc599bd69add087d56', // it can be an address or a symbol
      contractRewardLocker: '0x7359b9b1e4f9eee64b54b0d33d919a552f524532',
      contractStakeLocker: '0x5b68d1bf6ca8840b5fb18a2b4e421e7ae863c250',
      symbolEarn: 'WANA',
      decimalsLP: 18,
      allowanceFarm: 0,
      pendingReward: 0,
      amountStake: 0,
      balanceLP: 0,
      vestingDuration: 403200,
      apr: 15.61,
      multiplier: 0.16,// reward per block
      autoRestake: false,
      statusCompound: false,
      rewardLockerDuration: 0,
      stakeLockerByBlock: 5184000,
      disabled: true,
      isActive: false,
      textClose: 'Closed from July 31st 2022 - 23:59:59 UTC',
      linkDetail: 'https://bit.ly/3BaiyXI'
    },
  ],
  97: [
    // {
    //   icon: IconWanaka,
    //   namePair: 'WANA',
    //   addressLP: '0x56190b59760d472450f65dca7c54e50cf5ebbd41', // wana address
    //   pairTokenAndNative: '0x702f01801f61e91eef4a62ca52d796daa2323fd0', // wana-busd
    //   contractPool: '0xc7655a7e7B2C76b5a4d06C5a3448a0673D13af4C',
    //   token0: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7', // it can be an address or a symbol
    //   contractRewardLocker: '0xe4c65b318cdc6d3a8f7b5944b835408322d2561f',
    //   contractStakeLocker: '0x8827114b8e7e7e6b8ee8415e0fbb34bf9ae9053d',
    //   symbolEarn: 'WANA',
    //   decimalsLP: 18,
    //   allowanceFarm: 0,
    //   pendingReward: 0,
    //   amountStake: 0,
    //   balanceLP: 0,
    //   vestingDuration: 864000,
    //   apr: 0,
    //   multiplier: 0.16,// reward per block
    //   autoRestake: false,
    //   statusCompound: false,
    //   rewardLockerDuration: 0,
    //   stakeLockerByBlock: 5184000,
    // },
    // {
    //   icon: IconWanaka,
    //   namePair: 'WANA',
    //   addressLP: '0x56190b59760d472450f65dca7c54e50cf5ebbd41', // wana address
    //   pairTokenAndNative: '0x702f01801f61e91eef4a62ca52d796daa2323fd0', // wana-busd
    //   contractPool: '0xcFB5D8d41bf268B4281786e4DE392437C02FcB7a',
    //   token0: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7', // it can be an address or a symbol
    //   contractRewardLocker: '0xf7a41c05503bbff1dda281245cdf120cacd96083',
    //   contractStakeLocker: '0x8a32ab8d4ce572824934d619d4ae29261f75b4ed',
    //   symbolEarn: 'WANA',
    //   decimalsLP: 18,
    //   allowanceFarm: 0,
    //   pendingReward: 0,
    //   amountStake: 0,
    //   balanceLP: 0,
    //   vestingDuration: 864000,
    //   apr: 0,
    //   multiplier: 0.09,// reward per block
    //   autoRestake: false,
    //   statusCompound: false,
    //   rewardLockerDuration: 0,
    //   stakeLockerByBlock: 2592000,
    // },
    // {
    //   icon: IconWanaka,
    //   namePair: 'WAI',
    //   addressLP: '0xcd2d09fa9d425682085bc94ed0c7bada6cabcdba', // wai address
    //   pairTokenAndNative: '0x4c0b578299ff7767336f2c6deab1ce43501d6897', // wai-busd
    //   contractPool: '0x3236BECd5f21c1A686FB495E9c248C1Ac346A819',
    //   token0: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7', // it can be an address or a symbol
    //   contractRewardLocker: '0xe539ae13a46c6e5d729f1694ded8be6b81504c17',
    //   contractStakeLocker: '0xb8d0129e6d9e1e12ed8555b81ad271a9b6507560',
    //   symbolEarn: 'WAI',
    //   decimalsLP: 18,
    //   allowanceFarm: 0,
    //   pendingReward: 0,
    //   amountStake: 0,
    //   balanceLP: 0,
    //   vestingDuration: 0,
    //   apr: 0,
    //   multiplier: 0,// reward per block
    //   autoRestake: false,
    //   statusCompound: false,
    //   rewardLockerDuration: 0,
    //   stakeLockerByBlock: 172800,
    // },
  ],
};

const rootUrlsView = {
  56: {
    addLP: 'https://pancakeswap.finance/add/',
    viewContract: 'https://bscscan.com/address/',
    seePairInfo: 'https://pancakeswap.info/pair/',
    blocksPerMonth: 864000,
    swap: 'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=',
  },
  97: {
    addLP: 'https://pancake.kiemtienonline360.com/#/add/',
    viewContract: 'https://testnet.bscscan.com/address/',
    seePairInfo: 'https://pancake.kiemtienonline360.com/pair/',
    blocksPerMonth: 864000,
    swap: 'https://pancake.kiemtienonline360.com/#/swap?outputCurrency=',
  }
};

export const listChainsSupport = [
  { chainId: 56, name: 'BSC', icon: bnb, isETH: false, blockExplorer: {
    name: 'BscScan',
    url: 'https://bscscan.com',
  },/*BSC Mainnet*/ },
  { chainId: 97, name: 'BSC Testnet', icon: bnb, isETH: false, blockExplorer: {
    name: 'BscScan (testnet)',
    url: 'https://testnet.bscscan.com',
  }, /*BSC Testnet*/ },
];

const infoChains = {
  //BSC Mainnet
  56: { name: 'BSC', icon: bnb,blockExplorer: {
    name: 'BscScan',
    url: 'https://bscscan.com',
  } },
  //BSC Testnet
  97: { name: 'BSC-Testnet', icon: bnb, blockExplorer: {
    name: 'BscScan (testnet)',
    url: 'https://testnet.bscscan.com',
  } },
};

export const contractStakingV2 = {
  56: {
    WanakaFarmStaking: '0x071E3e98e7D35AA981Da9F69d5F96A7099772947',
    WanakaFarmToken: '0x339c72829ab7dd45c3c52f965e7abe358dd8761e',
    WanakaFarmLp: '',
    WanakaFarmTokenLP: ''
  },
  97: {
    WanakaFarmStaking: '0x5016181873CA27c45225B4B4f610C4595882717a',
    WanakaFarmToken: '0x56190b59760d472450f65dca7c54e50cf5ebbd41',
    WanakaFarmLp: '0x8ccd8f1519238CF477B6D6dec6730e1E524651C1',
    WanakaFarmTokenLP: '0x702f01801f61e91eef4a62ca52d796daa2323fd0'
  }
}

export const contractClaimWana = {
  56: {
    WanakaFarmClaim: '0x1b85c16081d0d8dffa1a4bddbb6a8c524e75da38',
    WanakaFarmToken: '0x339c72829ab7dd45c3c52f965e7abe358dd8761e',
    ApiClaimUrl: 'https://dist-api.wanakafarm.com/get-proof/claim-wana'
  },
  97: {
    WanakaFarmClaim: '0xb432E9ebb895a1647473072f55b4ce11E532daB6',
    WanakaFarmToken: '0x56190b59760d472450f65dca7c54e50cf5ebbd41',
    ApiClaimUrl: 'https://dist-api.wanakafarm.com/get-proof/claim-wana-testnet'
  }
}

export const getContractAddress = (_chainId) => {
  return contractAddress[_chainId];
};
export const getListTokensFarmDefault = (_chainId) => {
  return listTokensFarmDefault[_chainId];
};
export const getListTokensPoolDefault = (_chainId) => {
  return listTokensPoolDefault[_chainId];
};
export const getRootUrlView = (_chainId) => {
  return rootUrlsView[_chainId];
};
export const getInfoChain = (_chainId) => {
  return infoChains[_chainId];
};

export const getContractStakingV2 = (_chainId) => {
  return contractStakingV2[_chainId]
}

export const getContractClaimWana= (_chainId) => {
  return contractClaimWana[_chainId]
}
