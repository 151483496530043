import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { setChainId, setWeb3, setAddress, logout } from 'store/actions';
import store from 'store/index';
// import { getWeb3List } from 'utils/getWeb3List';
import { numberToHex, hexToNumber } from 'web3-utils';
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

const rpcSupport = {
  97: 'https://data-seed-prebsc-2-s1.binance.org:8545/',
  56: 'https://bsc-dataseed1.binance.org/'
};

const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Wanaka Farm"
    }
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: rpcSupport,
      network: 'binance',
      qrcodeModalOptions: {
        mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar'],
      },
      desktopLinks: [],
      bridge: 'https://bridge.walletconnect.org',
    },
  },
};

const paramsSwitchNetwork = {
  56: [
    {
      chainId: "0x38",
      chainName: 'BNB Smart Chain Mainnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://bsc-dataseed1.binance.org/'],
      blockExplorerUrls: ['https://bscscan.com/'],
    },
  ],
  97: [
    {
      chainId: '0x61',
      chainName: 'BNB Smart Chain Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://data-seed-prebsc-2-s1.binance.org:8545/'],
      blockExplorerUrls: ['https://testnet.bscscan.com/'],
    },
  ]
};

// export const selectChain = async (chainId, walletAddress, isETH) => {
//   if (!!rpcSupport[chainId]) {
//     if (!!walletAddress) {
//       isETH ? injectNetworkEthereum(chainId) : injectNetworkNoEthereum(chainId);
//     } else {
//       await store.dispatch(setWeb3(getWeb3List(chainId).web3Default));
//     }
//     await store.dispatch(setChainId(chainId));
//   } else {
//     alert('Farming does not support this network');
//   }
// };

// Switch for chains is not ETH
export const injectNetworkNoEthereum = async (chainId) => {
  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: paramsSwitchNetwork[chainId],
  });
};

// // Switch for chains in ecosystems of Ethereum
export const injectNetworkEthereum = async (chainId) => {
  await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [
      {
        chainId: numberToHex(chainId),
      },
    ],
  });
};


export const connectWeb3Modal = async () => {
  window.web3Modal = new Web3Modal({
    network: "binance",
    cacheProvider: false, // optional
    providerOptions, // required
  });

  window.provider = await window.web3Modal.connect();
  const supportChainId = 56
  if (window.ethereum) {
    if (!!paramsSwitchNetwork[supportChainId] && hexToNumber(window.provider.chainId) !== supportChainId) {
      try {
          await injectNetworkEthereum(supportChainId);
      } catch (error) {
        if (error.code === 4902) {
          await injectNetworkNoEthereum(supportChainId);
        }
      }
    }
  }
  const web3 = new Web3(window.provider);

  let chainID = await web3.eth.net.getId();
  if (!!rpcSupport[chainID]) {
    let accounts = await web3.eth.getAccounts();

    store.dispatch(setChainId(chainID));
    store.dispatch(setWeb3(web3));

    if (accounts.length > 0) {
      store.dispatch(setAddress(accounts[0]));
    }
  } else {
    if(window.provider && window.provider.close) {
      await window.provider.close();
      // If the cached provider is not cleared,
      // WalletConnect will default to the existing session
      // and does not allow to re-scan the QR code with a new wallet.
      // Depending on your use case you may want or want not his behavir.
    }
    await window.web3Modal.clearCachedProvider();
    window.provider = null;
    alert('Farming does not support this network');
  }

  // Subscribe to accounts change
  window.provider.on('accountsChanged', async (accounts) => {
    store.dispatch(setAddress(accounts[0]));
  });

  // Subscribe to chainID change
  window.provider.on('chainChanged', async (chainID) => {
    chainID = parseInt(web3.utils.hexToNumber(chainID));
    if (!!rpcSupport[chainID]) {
      store.dispatch(setChainId(chainID));
      store.dispatch(setWeb3(web3));
    } else {
      if(window.provider && window.provider.close) {
        await window.provider.close();
        // If the cached provider is not cleared,
        // WalletConnect will default to the existing session
        // and does not allow to re-scan the QR code with a new wallet.
        // Depending on your use case you may want or want not his behavir.
      }
      await window.web3Modal.clearCachedProvider();
        window.provider = null;
      alert('Farming does not support this network');
      await store.dispatch(logout());
    }
  });

  // Subscribe to provider connection
  window.provider.on('connect', (info) => {
    console.log(info);
  });

  // Subscribe to provider disconnection
  window.provider.on('disconnect', async (error) => {
    console.log(error);
    store.dispatch(setAddress(null));
  });
};
