import { Button as ButtonMUI, IconButton, Modal, TextField } from '@material-ui/core';
import { Button } from 'antd'
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios'
import { CloseRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import {notification} from 'utils/notification';

const StyledModal = styled(Modal)`
  display: flex;
  .MuiInputBase-root.Mui-disabled {
    color: rgb(255 255 255 / 70%);
    background: #565656;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #21c186;
  }
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline {
    border-color: rgb(255 255 255 / 60%);
  }
  input {
    color: #fff;
  }
  .MuiFormHelperText-root {
    color: #ccc;
  }
`;

// const StyledNotice = styled.p`
//   text-align: center;
//   color: #ccc;
//   span {
//     color: #fff;
//   }
// `;

const ModalWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 480px;
  border-radius: 8px;
  overflow: hidden;
  color: white;
  outline: none;
  position: relative;
  pointer-events: visible;
  cursor: pointer;
  margin: auto;
  background: #424242;
  color: #fff;
  @media (max-width: 767px) {
    min-width: calc(100% - 40px);
  }
`;

const StyledClose = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;

  .MuiIconButton-root {
    padding: 6px;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.06);
  }
`;

const StyledCloseRounded = styled(CloseRounded)`
  color: #c6c7c9;
`;

const StyledWrap = styled.div`

`;

const StyledBody = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
    color: #fff;
  }
  form {
    width: 100%;
  }
`;

const StyledButton = styled.div`
  .ant-btn {
    background: #21c186;
    border: none;
    background-image: linear-gradient(132deg, #3fbb98, #e7c81e);
    margin-top: 1rem;
    min-height: 48px;
    font-weight: 600;
    letter-spacing: 0.4px;
    font-size: 18px;
    font-family: 'ChakraPetch';
    color: #fff;
    width: 100%;
  }
`

const PopupConfirmEmail = () => {
  const { walletAddress } = useSelector((state) => state);
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const verifyFormRef = useRef(null);

  useEffect(() => {
    if(walletAddress && !window.localStorage.getItem('dontShowEmail')) {
      setOpen(true)
    }
  }, [walletAddress])

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault()
    axios.post("https://market-api-v1.wanakafarm.tech/api/staking-subscribe/create", {
      "email": email,
      "wallet": walletAddress
    })
    .then(() => {
      setEmail('');
      notification('Subscribe success', 'success');
      handelClose(true);
    })
    .catch((error) => {
      const msg = error.response && error.response.data && error.response.data.message
      notification(msg || 'Something went wrong', 'error');
    })
    .finally(() => {
      setLoading(false)
    })
  }
  function handleChangeEmail(e) {
    setEmail(e.target.value);
  }

  function handelClose(dontShow) {
    if(dontShow) {
      window.localStorage.setItem('dontShowEmail', 'true');
    }
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      setEmail('');
    }
  }, [open]);

  return (
    <StyledModal open={open} onClose={() => {handelClose(false)}}>
      <ModalWrapContent>
        <StyledClose>
          <IconButton onClick={() => {handelClose(false)}}>
            <StyledCloseRounded />
          </IconButton>
        </StyledClose>
        <StyledBody>
          <h2>Subscribe to Our Newsletter</h2>
          <form
            onSubmit={handleSubmit}
            ref={verifyFormRef}
            style={{ with: '100%' }}
          >
            {/* <StyledNotice>
              
            </StyledNotice> */}
            <StyledWrap>
              <div>
              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                label="Wallet Address"
                type="text"
                inputProps={{ maxLength: 6 }}
                value={walletAddress}
                disabled={true}
              />
              </div>
              <div>
              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                label="Email"
                focused={true}
                type="email"
                value={email}
                placeholder="Enter your email"
                onChange={handleChangeEmail}
                helperText="Get notification when your stake is unlocked"
              />
              </div>
              <StyledButton>
                <Button
                  disabled={isLoading}
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                >
                  SUBSCRIBE
                </Button>
              </StyledButton>
              <ButtonMUI
                  style={{marginTop: '1rem', textDecoration: 'underline', color: "rgb(32 193 134)", textTransform: 'capitalize'}}
                  disabled={isLoading}
                  onClick={() => {handelClose(true)}}
                  fullWidth={true}
                  color="primary"
                  variant="text"
                >
                  Don't show again
                </ButtonMUI>
            </StyledWrap>
          </form>
        </StyledBody>
      </ModalWrapContent>
    </StyledModal>
  );
};

export default PopupConfirmEmail;
