import { Button, Badge } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import { logout } from 'store/actions';
import { useState } from 'react';
import './index.css';
import { ExternalLink as LinkIcon } from 'react-feather';
import { getEtherscanLink} from '../../utils/helper';

export default function LogoutWallet() {
  const { shortAddress, walletAddress, chainId } = useSelector((state) => state);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const logoutAction = async () => {
    if(window.provider && window.provider.close) {
      await window.provider.close();
      // If the cached provider is not cleared,
      // WalletConnect will default to the existing session
      // and does not allow to re-scan the QR code with a new wallet.
      // Depending on your use case you may want or want not his behavir.
    }
    window.provider = null;
    await window.web3Modal.clearCachedProvider();
    dispatch(logout());
    cancelModal();
  };

  async function cancelModal() {
    setIsModalVisible(false);
  }

  return (
    <div className='logoutModal'>
      <Button className='bt-cnlo' shape='round' onClick={() => setIsModalVisible(true)}>
        <Badge status='success' /> &nbsp;
        {shortAddress}
      </Button>

      <Modal
        centered
        visible={isModalVisible}
        title={[
          <h2 key='title' className='textmode mgb-0'>
            Your wallet
          </h2>,
        ]}
        onOk={() => cancelModal}
        onCancel={() => cancelModal()}
        footer={[
          <div key='cancel' style={{ width: '100%' }} className='center'>
            <Button
              className='ant-btn ant-btn-primary btn-logout'
              onClick={() => logoutAction()}
              size='large'
              shape='round'
            >
              Logout
            </Button>
          </div>,
        ]}
      >
        <div key='content'>
          <div className='textmode wl-fs'>{walletAddress}</div>
          <a
            className="link-address"
            target="_blank"
            href={
              chainId &&
              getEtherscanLink(chainId, walletAddress, 'address')
            } rel="noreferrer"
          >
            <LinkIcon size={16} />
            <span style={{ marginLeft: '4px' }}>
              View on BscScan
            </span>
          </a>
        </div>
      </Modal>
    </div>
  );
}
