import { bnum } from 'utils/helper';
import ERC20 from 'Contracts/ERC20.json';
import Farm from 'Contracts/farm/Farm.json';
import WanaFarm from 'Contracts/farm/WanaFarm.json';
import WanaFarmV2 from 'Contracts/farm/WanaFarmV2.json';
import Vesting from 'Contracts/farm/Vesting.json';
import Pair from 'Contracts/IPancakePair.json';
import FeeDepositDaily from 'Contracts/farm/FeeDepositDaily.json';
import { notification } from 'antd';
import { getEtherscanLink } from 'utils/helper'
import Locker from 'Contracts/farm/Locker.json'
////////////////////
// Common
////////////////////
const openNotification = message => {
  notification.success({
    message: `${message.text}`,
    description: <div className="detail-transaction">Detail transaction <a href={getEtherscanLink(message.chainId,message.txHash,'transaction')} target="bank">here</a></div>,
    placement: 'bottomRight',
  });
};

export const LOGOUT = 'LOGOUT';
export const logout = () => (dispatch, getState) => {
  dispatch({ type: LOGOUT });
};

export const SET_WEB3 = 'SET_WEB3';
export const setWeb3 = (web3) => async (dispatch, getState) => {
  dispatch({ type: SET_WEB3, web3 });
};

export const SET_CHAINID = 'SET_CHAINID';
export const setChainId = (chainId) => (dispatch) => {
  localStorage.setItem('chainId', chainId);
  dispatch({ type: SET_CHAINID, chainId });
};

export const SET_LIST_TOKENS_FARM = 'SET_LIST_TOKENS_FARM';
export const setListTokensFarm = (listTokensFarm) => (dispatch) => {
  dispatch({ type: SET_LIST_TOKENS_FARM, listTokensFarm });
};

export const SET_LIST_TOKENS_POOL = 'SET_LIST_TOKENS_POOL';
export const setListTokensPool = (listTokensPool) => (dispatch) => {
  dispatch({ type: SET_LIST_TOKENS_POOL, listTokensPool });
};

export const SET_CONTRACT_ADDRESS = 'SET_CONTRACT_ADDRESS';
export const setContractAddress = (contractAddress) => (dispatch) => {
  dispatch({ type: SET_CONTRACT_ADDRESS, contractAddress });
};

export const SET_ADMIN_ADDRESS = 'SET_ADMIN_ADDRESS';
export const setAdminAddress = (addressesProvider) => async (dispatch) => {
  let adminAddress = await addressesProvider.methods.getAdmin().call();
  dispatch({
    type: SET_ADMIN_ADDRESS,
    adminAddress,
  });
};

export const SET_ADDRESS = 'SET_ADDRESS';
export const setAddress = (walletAddress) => (dispatch) => {
  // const walletAddress = '0xef6d3848e24AAb1b83543DDA14EACc514Bf6ef1f'
  if (!!walletAddress) {
    var shortAddress = `${walletAddress.slice(0, 4)}...${walletAddress.slice(
      walletAddress.length - 6,
      walletAddress.length
    )}`;
    dispatch({
      type: SET_ADDRESS,
      walletAddress,
      shortAddress,
    });
  }
};

export const SET_BALANCE = 'SET_BALANCE';
// export const setBalance = () => async (dispatch, getState) => {
//   let { walletAddress, chainId } = getState();
//   let balance;
//   if (walletAddress !== null) {
//     let contractStakingInfo = getContractStakingV2(chainId)
//     balance = await dispatch(fetchBalanceLP(contractStakingInfo.WanakaFarmToken))
//   } else {
//     balance = 0;
//   }
//   dispatch({
//     type: SET_BALANCE,
//     balance: parseBalance(balance, 18),
//   });
// };


export const SET_PRICE_WANA ='SET_PRICE_WANA'
export const setPriceWana = (priceWana) => (dispatch) => {
  dispatch({
    type: SET_PRICE_WANA,
    priceWana,
  });
};

export const SET_PRICE_WAI ='SET_PRICE_WAI'
export const setPriceWai = (priceWai) => (dispatch) => {
  dispatch({
    type: SET_PRICE_WAI,
    priceWai,
  });
};

export const SET_LIST_HISTORY_STAKE = 'SET_LIST_HISTORY_STAKE';
export const setListHistoryStake = (listHistoryStake) => (dispatch) => {
  dispatch({ type: SET_LIST_HISTORY_STAKE, listHistoryStake });
};

export const approveAll = (addressToken, contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();

  try {
    const instanceErc20 = new web3.eth.Contract(ERC20.abi, addressToken);
    await instanceErc20.methods
      .approve(contractFarm, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        openNotification({chainId, text: 'Approve Successfully !', txHash: receipt.transactionHash})
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Approve Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const checkAllowanceFarm = (addressToken, contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  try {
    const instanceErc20 = new web3.eth.Contract(ERC20.abi, addressToken);
    let allowance = await instanceErc20.methods.allowance(walletAddress, contractFarm).call();
    return allowance;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Allowance Error');
  }
};

export const fetchBalanceLP = (addressToken) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  try {
    if(!walletAddress) {
      return 0
    }
    const instanceErc20 = new web3.eth.Contract(ERC20.abi, addressToken);
    let balanceLP = await instanceErc20.methods.balanceOf(walletAddress).call();
    return balanceLP;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Balance LP Error!');
  }
};

export const fetchVestingTotalClaimableAmount = (contractVesting) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  if (contractVesting !== '0x0000000000000000000000000000000000000000') {
    try {
      const vestingInstance = new web3.eth.Contract(Vesting.abi, contractVesting);
      const numVestings = await vestingInstance.methods.getVestingCountByUser(walletAddress).call();
      if (!numVestings || numVestings === "0") {
        return 0
      }
      let claimableAmount = await vestingInstance.methods
        .getVestingTotalClaimableAmount(walletAddress, 0, numVestings)
        .call();
      return claimableAmount;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Vesting Claimable Error!');
    }
  }
};
export const fetchTotalAmountLockedByUserPool = (contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  if (web3 && contractPool !== '0x0000000000000000000000000000000000000000')
    try {
      const instance = new web3.eth.Contract(WanaFarm.abi, contractPool);
      let amountLocking = await instance.methods
        .getTotalAmountLockedByUser(walletAddress)
        .call();
      return amountLocking;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Amount Locked Error !');
    }
};

export const fetchTotalAmountLockedByUserPoolLock = (contractRewardLocker) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  if (web3 && contractRewardLocker !== '0x0000000000000000000000000000000000000000')
    try {
      const instance = new web3.eth.Contract(Locker.abi, contractRewardLocker);
      let amountLocking = await instance.methods
        .getTotalAmountLockedByUser(walletAddress)
        .call();
      return amountLocking;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Amount Locked Error !');
    }
};

export const fetchTotalClaimableAmountPool = (contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  if (contractPool !== '0x0000000000000000000000000000000000000000') {
    try {
      const instance = new web3.eth.Contract(WanaFarm.abi, contractPool);
      const numLocks = await instance.methods.getLockRewardCountByUser(walletAddress).call();
      if (!numLocks || numLocks === "0") {
        return
      }
      let claimableAmount = await instance.methods
        .getTotalClaimableAmount(walletAddress, 0, numLocks)
        .call();
      return claimableAmount;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Vesting Claimable Error!');
    }
  }
};

export const fetchTotalClaimableAmountPoolLock = (contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  if (contractPool !== '0x0000000000000000000000000000000000000000') {
    try {
      const instance = new web3.eth.Contract(Locker.abi, contractPool);
      const numLocks = await instance.methods.getLockerCountByUser(walletAddress).call();
      if (!numLocks || numLocks === "0") {
        return
      }
      let claimableAmount = await instance.methods
        .getTotalClaimableAmount(walletAddress, 0, numLocks)
        .call();
      return claimableAmount;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Vesting Claimable Error!');
    }
  }
};

export const fetchTotalAmountLockedByUser = (contractVesting) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  if (web3 && contractVesting !== '0x0000000000000000000000000000000000000000')
    try {
      const vestingInstance = new web3.eth.Contract(Vesting.abi, contractVesting);
      let amountLocking = await vestingInstance.methods
        .getTotalAmountLockedByUser(walletAddress)
        .call();
      return amountLocking;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Amount Locked Error !');
    }
};

export const claimTotalVesting = (contractVesting) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const vestingInstance = new web3.eth.Contract(Vesting.abi, contractVesting);
    const numVestings = await vestingInstance.methods.getVestingCountByUser(walletAddress).call();
    if (!numVestings || numVestings === "0") {
      return
    }
    await vestingInstance.methods
      .claimTotalVesting(0, numVestings)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        openNotification({chainId, text: 'Claim Successfully !', txHash: receipt.transactionHash})
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Claim Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const claimTotalLocking = (contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const wanaFarmInstance = new web3.eth.Contract(WanaFarm.abi, contractFarm);
    let numLocks = await wanaFarmInstance.methods.getLockRewardCountByUser(walletAddress).call();
    if (!numLocks || numLocks === "0") {
      return
    }
    const currentBlock = await web3.eth.getBlockNumber();
    numLocks = parseInt(numLocks)
    let startIndex = 0
    for (startIndex = 0; startIndex < numLocks; startIndex++) {
      let info = await wanaFarmInstance.methods.getLockRewardInfo(walletAddress, startIndex).call();
      if (!info || !info.isActive) {
        continue
      }
      if (currentBlock < parseInt(info.startBlock) + parseInt(info.lockDuration)) {
        continue
      }
      break
    }
    await wanaFarmInstance.methods
      .claimTotalReward(startIndex, numLocks)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        openNotification({chainId, text: 'Claim Successfully !', txHash: receipt.transactionHash})
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Claim Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const claimTotalLockingLock = (contractRewardLocker, contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const RewardLockerInstance = new web3.eth.Contract(Locker.abi, contractRewardLocker);
    const WanaFarmInstance = new web3.eth.Contract(WanaFarmV2.abi, contractPool);
    let numLocks = await RewardLockerInstance.methods.getLockerCountByUser(walletAddress).call();
    if (!numLocks || numLocks === "0") {
      return
    }
    const currentBlock = await web3.eth.getBlockNumber();
    numLocks = parseInt(numLocks)
    let startIndex = 0
    for (startIndex = 0; startIndex < numLocks; startIndex++) {
      let info = await RewardLockerInstance.methods.getLockerInfo(walletAddress, startIndex).call();
      if (!info || !info.isActive) {
        continue
      }
      if (currentBlock < parseInt(info.startBlock) + parseInt(info.fullockDuration)) {
        continue
      }
      break
    }
    await WanaFarmInstance.methods
      .claimReward(startIndex, numLocks)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        openNotification({chainId, text: 'Claim Successfully !', txHash: receipt.transactionHash})
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Claim Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const unstakeTotal = (contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const wanaFarmInstance = new web3.eth.Contract(Farm.abi, contractFarm);
    let numLocks = await wanaFarmInstance.methods.getStakingCountByUser(walletAddress).call();
    if (!numLocks || numLocks === "0") {
      return
    }
    const currentBlock = await web3.eth.getBlockNumber();
    numLocks = parseInt(numLocks)
    let startIndex = 0
    for (startIndex = 0; startIndex < numLocks; startIndex++) {
      let info = await wanaFarmInstance.methods.getStakeInfo(walletAddress, startIndex).call();
      if (!info || !info.isActive || Number(info.amount) === 0) {
        continue
      }
      if (currentBlock < parseInt(info.startBlock) + parseInt(info.lockDuration / 3)) {
        continue
      }
      break
    }
    await wanaFarmInstance.methods
      .unstakes(startIndex, numLocks)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        openNotification({chainId, text: 'Unstake Successfully !', txHash: receipt.transactionHash})
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Claim Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

const roundToTwoDp = (number) => Math.round(number * 100) / 100;
export const fetchAprPool = (addressLP, contractFarm, wana, yearlyWanaReward) => async (
  dispatch,
  getState
) => {
  const { web3 } = getState();
  try {
    const pair = new web3.eth.Contract(Pair.abi, addressLP);
    const totalLpTokenStaked = await pair.methods.balanceOf(contractFarm).call();
    const reserves = await pair.methods.getReserves().call();
    let token0 = await pair.methods.token0().call();
    let amountWana = 0;
    if (token0.toLowerCase() !== wana.toLowerCase()) {
      amountWana = reserves[1];
    } else {
      amountWana = reserves[0];
    }
    const totalSupply = await pair.methods.totalSupply().call();
    const LpTokenPriceWana = (amountWana * 2) / totalSupply;
    const poolLiquidityWana = totalLpTokenStaked * LpTokenPriceWana;
    const farm = new web3.eth.Contract(Farm.abi, contractFarm);
    const currentBlock = await web3.eth.getBlockNumber();
    const multiplier = await farm.methods.getMultiplier(currentBlock, currentBlock + 1).call();
    const rateConfig = await farm.methods.rateConfig().call();
    const rewardPerBlock = await farm.methods.rewardPerBlock().call();
    const yearlyWanaRewardAllocation = (multiplier * rewardPerBlock * rateConfig.reducingCycle * 12) / 1e12;

    let apr = (yearlyWanaRewardAllocation / poolLiquidityWana) * 100;
    apr = roundToTwoDp(apr);
    return apr;
  } catch (error) {
    console.debug("roundToTwoDp\n", error);
    return false;
  }
};

export const fetchTotalTokenLP = (addressTokenLP, addressContractFarm, wana) => async (
  dispatch,
  getState
) => {
  const { web3 } = getState();
  try {
    const instanceLP = new web3.eth.Contract(ERC20.abi, addressTokenLP);
    const instanceWana = new web3.eth.Contract(ERC20.abi, wana);
    let balanceWanaOfPair = await instanceWana.methods.balanceOf(addressTokenLP).call();
    let balanceLPOfFarm = await instanceLP.methods.balanceOf(addressContractFarm).call();
    let totalSupplyLP = await instanceLP.methods.totalSupply().call();
    let totalTokenLP = (2 * balanceWanaOfPair * balanceLPOfFarm) / totalSupplyLP;
    return { totalTokenLP, balanceLPOfFarm};
  } catch (error) {
    console.debug("fetchTotalTokenLP\n", error);
    // message.error('Fetch Total Token LP Error !');
  }
};

export const fetchPriceTokenWithUSDT = (
  contractAddress,
  decimalsWana
) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    if (!!contractAddress.PairUsdtNative) {
      // calculator price of native token ETH,BNB,...
      const instancePairUsdtNative = new web3.eth.Contract(
        Pair.abi,
        contractAddress.PairUsdtNative
      );
      let token0UsdtNative = await instancePairUsdtNative.methods.token0().call();
      let reservesUsdtNaive = await instancePairUsdtNative.methods.getReserves().call();
      let reserves0UsdtNative = reservesUsdtNaive[0],
        reserves1Token = reservesUsdtNaive[1];
      if (token0UsdtNative.toLowerCase() !== contractAddress.USDT.address.toLowerCase()) {
        [reserves0UsdtNative, reserves1Token] = [reserves1Token, reserves0UsdtNative];
      }
      let price =
        reserves0UsdtNative /
        Math.pow(10, contractAddress.USDT.decimals) /
        (reserves1Token / Math.pow(10, decimalsWana));
      return price;
    }
  } catch (error) {
    console.debug("fetchPriceTokenWithUSDT\n", error);
  }
};

export const fetchPriceTokenWithUSDT2 = (
  contractAddress,
  decimalsWana
) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    if (!!contractAddress.PairUsdtNative2) {
      // calculator price of native token ETH,BNB,...
      const instancePairUsdtNative = new web3.eth.Contract(
        Pair.abi,
        contractAddress.PairUsdtNative2
      );
      let token0UsdtNative = await instancePairUsdtNative.methods.token0().call();
      let reservesUsdtNaive = await instancePairUsdtNative.methods.getReserves().call();
      let reserves0UsdtNative = reservesUsdtNaive[0],
        reserves1Token = reservesUsdtNaive[1];
      if (token0UsdtNative.toLowerCase() !== contractAddress.USDT.address.toLowerCase()) {
        [reserves0UsdtNative, reserves1Token] = [reserves1Token, reserves0UsdtNative];
      }
      let price =
        reserves0UsdtNative /
        Math.pow(10, contractAddress.USDT.decimals) /
        (reserves1Token / Math.pow(10, decimalsWana));
      return price;
    }
  } catch (error) {
    console.debug("fetchPriceTokenWithUSDT\n", error);
  }
};

export const fetchVestingDuration = (contractVesting, blocksPerMonth) => async (
  dispatch,
  getState
) => {
  const { web3 } = getState();
  if (contractVesting !== '0x0000000000000000000000000000000000000000') {
    try {
      const vestingInstance = new web3.eth.Contract(Vesting.abi, contractVesting);
      let vestingDuration = await vestingInstance.methods.vestingDuration().call();
      return vestingDuration;
    } catch (error) {
      console.debug("fetchVestingDuration\n", error);
      // message.error('Fetch Vesting Claimable Error!');
    }
  }
  return 0;
};

// Functions use contract Farm
export const depositFarm = (amountWei, contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const farm = new web3.eth.Contract(Farm.abi, contractFarm);
    await farm.methods
      .deposit(amountWei)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        if (amountWei === 0) {
          openNotification({chainId, text: 'Harvest Successfully !', txHash: receipt.transactionHash})
        } else {
          openNotification({chainId, text: 'Stake Successfully !', txHash: receipt.transactionHash})
        }
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug("depositFarm\n", error);
        // message.error('Deposit Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const withdrawFarm = (amountWei, contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const farm = new web3.eth.Contract(Farm.abi, contractFarm);
    await farm.methods
      .withdraw(amountWei)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        openNotification({chainId, text: 'Unstake Successfully !', txHash: receipt.transactionHash})
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Withdraw Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const fetchPendingRewardFarm = (contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  try {
    const farm = new web3.eth.Contract(Farm.abi, contractFarm);
    let pendingReward = await farm.methods.pendingReward(walletAddress).call();
    return pendingReward;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Pending Reward Error !');
  }
};
export const fetchAmountStakeFarm = (contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  try {
    const farm = new web3.eth.Contract(Farm.abi, contractFarm);
    let amountStake = await farm.methods.userInfo(walletAddress).call();
    return amountStake.amount;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Amount Stake Error!');
  }
};

export const fetchTotalUnstakableAmount =(contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  // let listHistoryStakeClone = JSON.parse(JSON.stringify(listHistoryStake))
  // listHistoryStakeClone[contractPool] = []
  const listHistory = [];
  try {
    const wanaFarmInstance = new web3.eth.Contract(Farm.abi, contractPool);
    let numLocks = await wanaFarmInstance.methods.getStakingCountByUser(walletAddress).call();
    if (!numLocks || numLocks === "0") {
      return {totalUnstakable: 0, firstStartTime: 0, firstEndTime: 0, listHistory: []}
    }
    const currentBlock = await web3.eth.getBlockNumber();
    numLocks = parseInt(numLocks)
    let startIndex = 0;
    let flagIndex = true;
    let firstStartTime = 0;
    let firstEndTime = 0;
    let firstActive = false;
    for (let index = 0; index < numLocks; index++) {
      let info = await wanaFarmInstance.methods.getStakeInfo(walletAddress, index).call();
      if(Number(info.amount) !== 0) {
        listHistory.push(info)
      }
      if (!info || !info.isActive || Number(info.amount) === 0 || info.amount === info.claimedAmount) {
        continue
      }

      if(!firstActive ) {
        firstActive = true
        firstStartTime = info.startTime
        firstEndTime = parseInt(info.startTime) + parseInt(info.lockDuration)
      }
      if (currentBlock < parseInt(info.startBlock) + parseInt(info.lockDuration / 3)) {
        continue
      }
      if(flagIndex) {
        startIndex = index
        flagIndex = false
      }
    }

    let totalUnstakable = await wanaFarmInstance.methods
      .getTotalUnstakableAmount(walletAddress, startIndex, numLocks).call();

    // dispatch(setListHistoryStake(listHistoryStakeClone))
    return {totalUnstakable, firstStartTime, firstEndTime, listHistory}
  } catch (error) {
    console.debug(error);
    return {totalUnstakable: 0, firstStartTime: 0, firstEndTime: 0, listHistory: []}
  }
}

export const fetchInfoStakeAmountPool = (contractLocker) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  // let listHistoryStakeClone = JSON.parse(JSON.stringify(listHistoryStake))
  // listHistoryStakeClone[contractLocker] = []
  const listHistory = []
  try {
    const wanaFarmInstance = new web3.eth.Contract(Locker.abi, contractLocker);
    let numLocks = await wanaFarmInstance.methods.getLockerCountByUser(walletAddress).call();
    if (!numLocks || numLocks === "0") {
      return {totalUnstakable: 0, firstStartTime: 0, firstEndTime: 0, listHistory: []}
    }
    const currentBlock = await web3.eth.getBlockNumber();
    numLocks = parseInt(numLocks)
    let startIndex = 0;
    let flagIndex = true;
    let firstStartTime = 0;
    let firstEndTime = 0;
    let firstActive = false;
    // console.log('contractLocker', contractLocker)
    for (let index = 0; index < numLocks; index++) {
      let info = await wanaFarmInstance.methods.getLockerInfo(walletAddress, index).call();
      //
      if(Number(info.amount) !== 0) {
        listHistory.push(info)
      }
      //
      if (!info || !info.isActive || Number(info.amount) === 0 || info.amount === info.claimedAmount) {
        continue
      }
      if(!firstActive) {
        firstActive = true
        firstStartTime = info.startTime
        firstEndTime = parseInt(info.startTime) + parseInt(info.fullockDuration * 3)
      }
      if (currentBlock < parseInt(info.startBlock) + parseInt(info.fullockDuration)) {
        continue
      }
      if(flagIndex) {
        flagIndex = false
        startIndex = index
      }
    }
    let totalUnstakable= 0
    totalUnstakable = await wanaFarmInstance.methods
      .getTotalClaimableAmount(walletAddress, startIndex, numLocks).call();
    // dispatch(setListHistoryStake(listHistoryStakeClone))
    return {totalUnstakable, firstStartTime, firstEndTime, listHistory}
  } catch (error) {
    console.debug(error);
    return {totalUnstakable: 0, firstStartTime: 0, firstEndTime: 0, listHistory: []};
  }
}

export const fetchLockConfig =(contractFarm) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const wanaFarmInstance = new web3.eth.Contract(Farm.abi, contractFarm);
    const lockConfig = await wanaFarmInstance.methods.lockConfig().call();
    return lockConfig
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export const fetchMultiplierFarm = (contractFarm) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const farm = new web3.eth.Contract(Farm.abi, contractFarm);
    const currentBlock = await web3.eth.getBlockNumber();
    const multiplier = await farm.methods.getMultiplier(currentBlock, currentBlock + 1).call();
    return multiplier;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Reward Per Block Error !');
    return false;
  }
};

export const fetchActive = (contractFarm) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const farm = new web3.eth.Contract(Farm.abi, contractFarm);
    const isActive = await farm.methods.isActive().call();
    return isActive;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Reward Per Block Error !');
    return false;
  }
};


// Functions use contract WanaFarm
export const depositPool = (amountWei, contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const instancePool = new web3.eth.Contract(WanaFarm.abi, contractPool);
    await instancePool.methods
      .deposit(amountWei)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        if (amountWei === 0) {
          openNotification({chainId, text: 'Harvest Successfully !', txHash: receipt.transactionHash})
        } else {
          openNotification({chainId, text: 'Stake Successfully !', txHash: receipt.transactionHash})
        }
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Deposit Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const withdrawPool = (amountWei, contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const farm = new web3.eth.Contract(WanaFarm.abi, contractPool);
    await farm.methods
      .withdraw(amountWei)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        openNotification({chainId, text: 'Unstake Successfully !', txHash: receipt.transactionHash})
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Withdraw Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const withdrawPoolLock = (contractLocker, contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress, chainId } = getState();
  try {
    const wanaFarmInstance = new web3.eth.Contract(WanaFarmV2.abi, contractPool);
    const lockerInstance = new web3.eth.Contract(Locker.abi, contractLocker);
    let numLocks = await lockerInstance.methods.getLockerCountByUser(walletAddress).call();
    if (!numLocks || numLocks === "0") {
      return
    }
    const currentBlock = await web3.eth.getBlockNumber();
    numLocks = parseInt(numLocks)
    let startIndex = 0
    for (startIndex = 0; startIndex < numLocks; startIndex++) {
      let info = await lockerInstance.methods.getLockerInfo(walletAddress, startIndex).call();
      if (!info || !info.isActive || Number(info.amount) === 0) {
        continue
      }
      if (currentBlock < parseInt(info.startBlock) + parseInt(info.fullockDuration)) {
        continue
      }
      break
    }
    await wanaFarmInstance.methods
      .unstake(startIndex, numLocks)
      .send({ from: walletAddress })
      .on('receipt', (receipt) => {
        openNotification({chainId, text: 'Unstake Successfully !', txHash: receipt.transactionHash})
        return true;
      })
      .on('error', (error, receipt) => {
        console.debug(error);
        // message.error('Claim Error !');
        return false;
      });
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const fetchPendingRewardPool = (contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  try {
    const farm = new web3.eth.Contract(WanaFarm.abi, contractPool);
    let pendingReward = await farm.methods.pendingToken(walletAddress).call();
    return pendingReward;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Pending Reward Error !');
  }
};

export const fetchPendingRewardPoolLock = (contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  try {
    const farm = new web3.eth.Contract(WanaFarmV2.abi,  contractPool);
    let pendingReward = await farm.methods.pendingReward(walletAddress).call();
    return pendingReward;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Pending Reward Error !');
  }
};


export const fetchAmountStakePool = (contractPool) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  try {
    const farm = new web3.eth.Contract(WanaFarm.abi, contractPool);
    let amountStakedPool = await farm.methods.userInfo(walletAddress).call();
    return amountStakedPool.amount;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Amount Stake Error!');
  }
};

export const fetchMultiplierPool = (contractPool) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const farm = new web3.eth.Contract(WanaFarm.abi, contractPool);
    const currentBlock = await web3.eth.getBlockNumber();
    const multiplier = await farm.methods.getMultiplier(currentBlock, currentBlock + 1).call();
    return multiplier;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Reward Per Block Error !');
    return false;
  }
};

export const fetchStartBlock= (contractPool) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const farm = new web3.eth.Contract(WanaFarm.abi, contractPool);
    const startBlock = await farm.methods.startBlock().call();
    return startBlock
  } catch (error) {
    console.error(error);
    // message.error('Fetch Reward Per Block Error !');
    return false;
  }
};

export const fetchTotalTokenPool = (addressTokenLP, contractPool) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const instanceLP = new web3.eth.Contract(ERC20.abi, addressTokenLP);
    let balanceLPOfPool = await instanceLP.methods.balanceOf(contractPool).call();
    return balanceLPOfPool;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Total Token LP Error !');
  }
};

export const fetchTotalTokenPoolLock = (contractPool) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const WanaFarmInstance = new web3.eth.Contract(WanaFarmV2.abi, contractPool);
    let total = await WanaFarmInstance.methods.totalStakeAmount().call();
    return total;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Total Token LP Error !');
  }
};


export const fetchApyPoolLock = (contractPool) => async (
  dispatch,
  getState
) => {
  const { web3 } = getState();
  try {
    // const tokenLP = new web3.eth.Contract(ERC20.abi, addressLP);
    const farm = new web3.eth.Contract(WanaFarmV2.abi, contractPool);
    const totalLpTokenInPool = await farm.methods.totalStakeAmount().call();
    const currentBlock = await web3.eth.getBlockNumber();
    const multiplier = await farm.methods.getMultiplier(currentBlock, currentBlock + 1).call();
    const rateConfig = await farm.methods.rateConfig().call()
    const rewardPerBlock = await farm.methods.getTokenPerBlock().call();
    const yearlyWanaRewardAllocation = (multiplier * rewardPerBlock * rateConfig.reducingCycle * 12) / 1e12;
    let apr = yearlyWanaRewardAllocation / totalLpTokenInPool;
    apr = roundToTwoDp(apr * 100);
    return apr;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Apy Error !');
    return false;
  }
};

export const fetchApyPool = (addressLP, contractPool) => async (
  dispatch,
  getState
) => {
  const { web3 } = getState();
  try {
    const tokenLP = new web3.eth.Contract(ERC20.abi, addressLP);
    const totalLpTokenInPool = await tokenLP.methods.balanceOf(contractPool).call();
    const farm = new web3.eth.Contract(WanaFarm.abi, contractPool);
    const currentBlock = await web3.eth.getBlockNumber();
    const multiplier = await farm.methods.getMultiplier(currentBlock, currentBlock + 1).call();
    const rateConfig = await farm.methods.rateConfig().call()
    const rewardPerBlock = await farm.methods.getTokenPerBlock().call();
    const yearlyWanaRewardAllocation = (multiplier * rewardPerBlock * rateConfig.reducingCycle * 12) / 1e12;
    let apr = yearlyWanaRewardAllocation / totalLpTokenInPool;
    let dailyAPR = apr / 365;
    let apy = (1 + dailyAPR) ** 365 - 1;
    apy = roundToTwoDp(apy * 100);
    return apy;
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Apy Error !');
    return false;
  }
};

export const calcPercentStakedPool = (addressLP, contractPool) => async (dispatch, getState) => {
  const { walletAddress, web3 } = getState();
  if (!!walletAddress) {
    try {
      const tokenLP = new web3.eth.Contract(ERC20.abi, addressLP);
      const totalLpTokenInPool = await tokenLP.methods.balanceOf(contractPool).call();
      const farm = new web3.eth.Contract(WanaFarm.abi, contractPool);
      const amountStakedPool = await farm.methods.userInfo(walletAddress).call();
      const percentInPool = bnum(amountStakedPool.amount).dividedBy(totalLpTokenInPool).multipliedBy(100).toFixed(2)
      return percentInPool;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Apy Error !');
      return false;
    }
  }
};

export const calcPercentStakedPoolLock = (contractPool) => async (dispatch, getState) => {
  const { walletAddress, web3 } = getState();
  if (!!walletAddress) {
    try {
      const farm = new web3.eth.Contract(WanaFarmV2.abi, contractPool)
      const totalLpTokenInPool = await farm.methods.totalStakeAmount().call();
      const amountStakedPool = await farm.methods.userInfo(walletAddress).call();
      const percentInPool = bnum(amountStakedPool.amount).dividedBy(totalLpTokenInPool).multipliedBy(100).toFixed(2)
      return percentInPool;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Apy Error !');
      return false;
    }
  }
};

export const calcPercentStakedFarm = (addressLP, contractFarm) => async (dispatch, getState) => {
  const { walletAddress, web3 } = getState();
  if (!!walletAddress) {
    try {
      const tokenLP = new web3.eth.Contract(ERC20.abi, addressLP);
      const totalLpTokenInPool = await tokenLP.methods.balanceOf(contractFarm).call();
      const farm = new web3.eth.Contract(Farm.abi, contractFarm);
      const amountStakedPool = await farm.methods.userInfo(walletAddress).call();
      const percentInFarm = ((amountStakedPool.amount / totalLpTokenInPool) * 100).toFixed(2);
      return percentInFarm;
    } catch (error) {
      console.debug(error);
      // message.error('Fetch Apy Error !');
      return false;
    }
  }
};

export const fetchFeePercent = (contractFeeDeposit) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const contractFee = new web3.eth.Contract(FeeDepositDaily.abi, contractFeeDeposit);
    const feePercent = await contractFee.methods.getFeePercent().call();
    return feePercent
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Apy Error !');
    return false;
  }
}

export const fetchStartCycle = (contractFeeDeposit) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const contractFee = new web3.eth.Contract(FeeDepositDaily.abi, contractFeeDeposit);
    const startCycle = await contractFee.methods.startCycle().call();
    return startCycle
  } catch (error) {
    console.debug(error);
    // message.error('Fetch Apy Error !');
    return false;
  }
}


export const getStakedPoolV2 =  (abi,contractFarm) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const stakingContract = new web3.eth.Contract(abi, contractFarm);
    const detailStaked = await stakingContract.methods.getDetailStakedPool().call();
    return detailStaked;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAPRPoolV2 =  (abi,contractFarm, index1, index2) => async (dispatch, getState) => {
  const { web3 } = getState();
  try {
    const stakingContract = new web3.eth.Contract(abi, contractFarm);
    const apr = await stakingContract.methods.getDetailAllApr().call();
    return apr;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getStakerInfoV2 =  (abi,contractFarm) => async (dispatch, getState) => {
  const { web3, walletAddress } = getState();
  try {
    const stakingContract = new web3.eth.Contract(abi, contractFarm);
    return stakingContract.methods.getStakerInfo(walletAddress, 0, 99999).call();
  } catch (error) {
    console.log(error);
    return false;
  }
};
