import { getInfoChain } from './getContractAddress'
import { BigNumber } from 'bignumber.js';
import PackageData from '__mocks__/PackageData';

export function parseBalance(_balanceWei, _decimals) {
  if (!_balanceWei) {
    return 0;
  }
  if (!_decimals) {
    _decimals = 18;
  }
  _decimals = parseInt(_decimals);
  let afterDecimal;
  const weiString = _balanceWei.toString();
  const trailingZeros = /0+$/u;

  const beforeDecimal =
    weiString.length > _decimals ? weiString.slice(0, weiString.length - _decimals) : '0';
  afterDecimal = ('0'.repeat(_decimals) + _balanceWei).slice(-_decimals).replace(trailingZeros, '');
  if (afterDecimal === '') {
    afterDecimal = '0';
  } else if (afterDecimal.length > 3) {
    afterDecimal = afterDecimal.slice(0, 3);
  }
  return parseFloat(`${beforeDecimal}.${afterDecimal}`);
}

export function parseBalanceNoToFixed(_balanceWei, _decimals) {
  if (!_balanceWei) {
    return 0;
  }
  if (!_decimals) {
    _decimals = 18;
  }
  _decimals = parseInt(_decimals);
  let afterDecimal;
  const weiString = _balanceWei.toString();
  const trailingZeros = /0+$/u;

  const beforeDecimal =
    weiString.length > _decimals ? weiString.slice(0, weiString.length - _decimals) : '0';
  afterDecimal = ('0'.repeat(_decimals) + _balanceWei).slice(-_decimals).replace(trailingZeros, '');
  if (afterDecimal === '') {
    afterDecimal = '0';
  }
  return parseFloat(`${beforeDecimal}.${afterDecimal}`);
}

export function convertTimestampToDate(timestamp) {
  var months_arr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  // Convert timestamp to milliseconds
  var date = new Date(timestamp * 1000);
  // Year
  var year = date.getFullYear();
  // Month
  var month = months_arr[date.getMonth()];
  // Day
  var day = date.getDate();
  // Hours
  var hours = date.getHours();
  // Minutes
  var minutes = date.getMinutes() + 1;

  // Display date time in MM-dd-yyyy h:m:s format
  var convdataTime = `${year !== new Date().getFullYear() ? year : ''} ${month} ${
    day < 10 ? '0' + day : day
  }, ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes} `;

  return convdataTime;
}

export function getEtherscanLink(
  chainId,
  data,
  type
) {
  const cid = chainId || 56;
  const network = getInfoChain(cid);
  const prefix = network?.blockExplorer?.url;

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`;
    }
    case 'token': {
      return `${prefix}/token/${data}`;
    }
    case 'block': {
      return `${prefix}/block/${data}`;
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`;
    }
  }
}

export function bnum(
  val
) {
  return !val ? new BigNumber(0) : new BigNumber(val.toString());
}

export function numberFormat(
  number,
  decimals = null,
  thousandsSep = ',',
  decPoint = '.',
  trailingZeros = false
) {
  if (typeof number === 'undefined') {
    return;
  }
  // Strip all characters but numerical ones.
  const numerical = `${number}`.replace(/[^0-9+\-Ee.]/g, '');
  const n = !isFinite(+numerical) ? 0 : +numerical;
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
  let s = [];
  const toFixedFix = function(n, prec) {
    const k = Math.pow(10, prec);
    return `${Math.round(n * k) / k}`;
  };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (decimals === null
    ? `${n}`
    : prec
    ? toFixedFix(n, prec)
    : `${Math.round(n)}`
  ).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    if (trailingZeros) {
      // 1.123 with decimals = 5 => 1.12300
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
  }
  return s[1] ? s.join(dec) : s[0];
}

export function amountFormat(num, decimals = 2) {
  const _num = bnum(num);
  let d = decimals;
  if (_num.gt(0)) {
    if (_num.lt(1)) {
      d = 6;
    } else if (_num.gte(1e5)) {
      d = 0;
    }
  }
  return numberFormat(num, d);
}

export function addressEquals(addr1, addr2) {
  return addr1 && addr2 ? addr1.toLowerCase() === addr2.toLowerCase() : false;
}

export function abbreviateNumber(
  number,
  min = 1e3,
  digits = 2,
  options = { thousandsSep: ',' }
) {
  if (!number || isNaN(number)) return number;
  const num = +number;
  if (num < min) {
    return formatBalanceTruncated(num, options);
  }

  const units = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
  let decimal = 0;

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }

  return `${num}`;
}

export const formatBalanceTruncated = (
  balance,
  options
) => {
  if (!balance) {
    return '0.00';
  }
  let value;
  if (typeof balance === 'string') {
    value = balance;
  } else if (typeof balance === 'number') {
    value = balance.toString();
  } else {
    value = balance.toFixed();
  }
  if (bnum(value).gt(0)) {
    if (options.decimals && bnum(value).lt(Math.pow(10, -options.decimals))) {
      return `<0.${new Array(options.decimals).join('0')}1`;
    }
    if (bnum(value).lt(1e-5)) {
      return '<0.00001';
    }
  }
  const integralPart = Math.floor(parseFloat(value)).toString();
  const _decimals =
    options.decimals !== undefined
      ? options.decimals
      : Math.max(6 - integralPart.length, 1);
  return numberFormat(value, _decimals, options.thousandsSep || '');
};

export const secondToTime = (s) => {
  const d = Math.floor(s / (3600 * 24));
  s  -= d * 3600 * 24;

  const h = Math.floor(s / 3600);
  s  -= h * 3600;

  const m = Math.floor(s / 60);
  s  -= m * 60;

  const tmp = [];

  (d) && tmp.push(d + (d > 1 ? ' days': ' day'));
  if(d === 0) {
    (h) && tmp.push(h + (h > 1 ? ' hours': ' hour'));
  }
  if (d === 0 && h === 0) {
    (d || h || m) && tmp.push(m + (m > 1 ? ' minutes': ' minute'));
  }
  // if(s !== 0) {
  //   tmp.push(s + 's');
  // }
  return tmp.join(' ');
}

export const addZero = value => {
  return value > 9 ? value : `0${value}`;
};

export const formatDate = timestamp => {
  if(!timestamp) {
    return '__'
  }
  const date = new Date(timestamp * 1000);
  return `${date.toLocaleDateString("en-US")}`;
  // return `${date.toLocaleDateString("en-US")} ${addZero(date.getHours())}:${addZero(
  //   date.getMinutes()
  // )}`;
};

export const convertAprToApy = (apr, day)=>{
  return ((1 + apr/365) * apr).toFixed(2)
}

export const flatten = (arr) => {
  return arr.reduce((pre, cur) => {
    return pre.concat(Array.isArray(cur) ? flatten(cur) : cur)
  }, [])
}

const NONE_PACKAGE = 0
const PACKAGE_SILVER = 1
const PACKAGE_GOLD = 2
const PACKAGE_RUBY = 3
const PACKAGE_DIAMOND = 4
const NONE_PACKAGE_LP = 10
const PACKAGE_SILVER_LP = 5
// const PACKAGE_GOLD_LP = 6
// const PACKAGE_RUBY_LP = 7
// const PACKAGE_DIAMOND_LP = 8
export function findPackageByAmount(amount, contractFarm) {
    if(addressEquals(PackageData.WANA[0].contractFarm, contractFarm)) {
      if (amount < 250 || amount >= 20000000) {
        return NONE_PACKAGE
      } else if (amount >= 250 && amount < 4000) {
          return PACKAGE_SILVER
      } else if (amount >= 4000 && amount < 15000) {
          return PACKAGE_GOLD
      } else if (amount >= 15000 && amount < 30000) {
          return PACKAGE_RUBY
      } else if (amount >= 30000) {
          return PACKAGE_DIAMOND
      }
    } else {
      if (amount < 250 || amount >= 20000000) {
        return NONE_PACKAGE_LP
      } else {
        return PACKAGE_SILVER_LP
      }
    }
}

export const formatAddress = (address) => {
  return address.substr(0, 8) + ' ... ' + address.substr(address.length - 8, address.length);
};