import { HashRouter, Switch, Route } from 'react-router-dom';
import NavBar from 'Components/NavBar';
import IconLoading from 'Components/IconLoading';
import PopupSubscribeEmail from 'Components/PopupSubscribeEmail'

import { lazy, Suspense } from 'react';
import './App.css';

const Farm = lazy(() => import('Views/Farm'));
const Farm2 = lazy(() => import('Views/Farm2'));
const ClaimWana = lazy(() => import('Views/ClaimWana'));

function App() {
  // useEffect(() => {
  //   async function fetchDataInit() {
  //     document
  //       .getElementsByTagName('HTML')[0]
  //       .setAttribute('data-theme', localStorage.getItem('theme') || 'dark');
  //   }
  //   fetchDataInit();
  // }, []);
  return (
    <div style={{ height: '100vh' }}>
      <HashRouter>
        <div className='page content'>
          <NavBar />
          <PopupSubscribeEmail></PopupSubscribeEmail>
          <Suspense
            fallback={
              <div className='center background-mode' style={{ height: '100%' }}>
                <IconLoading />
              </div>
            }
          >
            <Switch>
              <Route exact path='/old-staking' component={Farm} />
              <Route exact path='/' component={Farm2} />
              <Route exact path='/claim-wana' component={ClaimWana} />
            </Switch>
          </Suspense>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
