import LeftMenu from './LeftMenu';
import RightMenu from './RightMenu';
import { NavLink, Link } from 'react-router-dom';
import logoWanaka from 'Assets/logo-wanaka.svg';
import styled from 'styled-components';

const StyledLink = styled.div`
  padding: 7px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const StyledNavLink = styled(NavLink).attrs(() => ({
  activeClassName: 'active'
}))`
  color: #9696b1;
  margin: 5px 8px 5px 5px;
  border: 2px solid #5f5f84;
  border-radius: 50px;
  padding: 5px 10px;
  white-space: nowrap;
  &.active {
    color: #fff;
    background-color: #10c6ac;
    border-color: #10c6ac;
  }
  &:hover {
    color: #fff;
    border-color: #10c6ac;
  }
`;

export default function NavBar() {
  return (
    <nav className='menu-bar alignItem'>
      <div className='left-menu'>
        <div className='logo'>
          <Link to='/'>
            <img src={logoWanaka} alt='logo'></img>
          </Link>
        </div>
        <StyledLink>
          <StyledNavLink to="/" exact>Staking pool V2</StyledNavLink>
          <StyledNavLink to="/old-staking" exact>Old staking</StyledNavLink>
        </StyledLink>
      </div>
      <div className='rightMenu'>
      <div className='info-chain'>
          <LeftMenu />
        </div>
        <RightMenu />
      </div>
    </nav>
  );
}
