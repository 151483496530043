import { createStandaloneToast } from '@chakra-ui/react'

const toast = createStandaloneToast()

export const notification = (
    description,
    status
) => {
    return toast({
        title: 'WanakaFarm',
        description: description,
        status: status,
        duration: 15000,
        position: 'bottom-right',
        isClosable: true,
    })
}
