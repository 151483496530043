export default function IconLoading() {
  return (
    <div className='box-loading'>
      <div className='line-loading'>
        <div className='line-loading'>
          <div className='line-loading'>
            <div className='line-loading'>
              <div className='line-loading'>
                <div className='line-loading'>
                  <div className='line-loading'>
                    <div className='line-loading'>
                      <div className='line-loading'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
